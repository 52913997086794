import React from "react"
import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import HeroVideo from "~/components/page/HeroVideo"
import { graphql, useStaticQuery } from "gatsby"
import { Infobox } from "~/components/page/Infobox"
import { Text } from "~/components/common/Typography"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"

const Page = ({ myPage = 3, mySubpage = 0 }) => {
  const data = useStaticQuery(graphql`
      query {
          video: file(
              name: { eq: "ADIDAS_DigitalCurriculum_Video7_CTM_190923_3bit" }
          ) {
              name
              publicURL
          }
      }
  `)

  const { video } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge={
          "As girls enter their preteen and teenage years, they experience many physical and emotional changes, as well as gender-related challenges and stressors."
        }
        videoSrc={video.publicURL}
        videoLabel={video.name}
        pb={0}
      />

      <Infobox background={"seafoam"} maxWidth={"desktop"}>
        <Text mb="5" mt={0}>
          We know that boys and girls both experience stress in adolescence. We
          also know that sports can be a vital coping mechanism in combating
          stress. Yet studies and research suggest girls experience greater
          stress than boys and have less access to sports to cope with it. As a
          coach, you can use sports to help girls learn to manage and overcome
          stress.
        </Text>
      </Infobox>

      <Infobox maxWidth={"desktop"} uppercase>
        Stress is a reality for all girls. It can come in many different forms
        and many different contexts. Stress can come from their peers, school,
        bodily changes, family, and even pressure to perform within sports.
        <br/>
        <br/>
        Research shows that girls report significantly higher rates of
        psychological distress, anxiety, and depression and that girls with poor
        social support are more vulnerable to mental health problems than those
        with strong social support. Improving social support may serve as a
        protective buffer to stress and mental health problems
        <Source>24</Source>. Sports can provide girls with social support.
        <br/>
        <br/>
        This lesson will specifically focus on understanding how girls’ brains
        respond to stress. There is more than meets the eye when it comes to the
        actions of the girls in your program, and when we understand how the
        brain works, we can better understand the origin of the behavior we see
        and hear.
      </Infobox>
      <Copyright bg={"red"}/>
    </Layout>
  )
}

export default withProgress(Page, 3, 0)
